<template>
  <div id="modal-overflow" class="uk-modal" uk-modal>
    <div class="uk-modal-dialog bggray w-75 no-padding">
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <div class="uk-modal-header">
        <h3 class="uk-modal-title">
          {{ title ? title : $t("user_group.add_user_group") }} ({{
            selectedUsers.length
          }})
        </h3>
      </div>

      <div class="uk-modal-body">
        <div class="uk-card-header pl-0 pr-0">
          <div class="float-left d-flex">
            <button @click="allToggle(type)" type="button" class="btn btn-sm btn-soft-secondary">
              <i class="uil-check-square"></i> {{ selectAllText }}
            </button>
            <span class="btn ml-2 btn-sm bg-section-secondary text-muted" v-if="allowed_number">Maksimum({{
              allowed_number
            }})</span>
          </div>
          <div class="float-right d-flex">
            <div class="btn-group bootstrap-select ml-3">
              <select class="selectpicker ml-1" tabindex="-98" @change="filterResult()" v-model="per_page">
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>
            </div>
          </div>
        </div>

        <div class="card mb-0">
          <!-- Card header -->
          <div class="card-header actions-toolbar border-0">
            <div class="d-flex justify-content-between align-items-center">
              <!-- <h4 class="d-inline-block mb-0"></h4> -->
              <!-- <div>
                <a
                  @click="filterResult"
                  href="javascript:void(0)"
                  class="btn btn-icon btn-hover btn-circle"
                  aria-expanded="false"
                >
                  <i class="uil-search"></i>
                </a>
                <div
                  class="uk-drop"
                  uk-drop="mode: hover; pos: right-center; offset: 0"
                  style="left: 91px; top: 159.297px"
                >
                  <span class="uk-search uk-search-navbar uk-width-1-1">
                    <input
                      class="uk-search-input shadow-0 uk-form-small"
                      type="search"
                      @change="filterResult"
                      v-model="filter.search"
                      :placeholder="$t('general.search')"
                      autofocus=""
                    />
                  </span>
                </div> -->
              <div class="row" style="width:100%">
                <div class=" col-md-1  search-icon ">
                  <i class="uil-search"></i>
                </div>
                <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
                  <input class="uk-search-input shadow-0 uk-form-small" type="search" v-model="filter.search"
                    :placeholder="$t('general.search')" @input="filterResult" />
                </div>
              </div>
              <div class="d-flex">
                <!-- Shorting -->
                <a href="javascript:void(0)" class="btn btn-icon btn-hover btn-circle"
                  :uk-tooltip="$t('filters.order.sort')" title="" aria-expanded="false">
                  <i class="uil-list-ui-alt"></i>
                </a>
                <div ref="filterRef" uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up"
                  class="uk-dropdown">
                  <ul class="uk-nav uk-dropdown-nav">
                    <li class="uk-active">
                      <a href="javascript:void(0)" @click="sortOrder('asc', 'name')">{{ $t("filters.order.a_z") }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" @click="sortOrder('desc', 'name')">{{ $t("filters.order.z_a") }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" @click="sortOrder('asc', 'created_at')">
                        {{ $t("filters.order.created_at_asc") }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" @click="sortOrder('desc', 'created_at')">
                        {{ $t("filters.order.created_at_desc") }}</a>
                    </li>
                  </ul>
                </div>
                <!-- Filter -->
                <a class="btn btn-icon btn-hover  btn-circle" href="javascript:void(0)"
                  :uk-tooltip="$t('general.filter_list')">
                  <i class="uil-filter"></i>
                </a>
                <UserFilter id="userFilterModal" @getFilter="getFilter($event)" :isDate="false"></UserFilter>
              </div>
            </div>
          </div>
          <!-- Table -->
          <div class="table-responsive">
            <table class="table align-items-center">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{{ this.$t('general.first_name_and_last_name') }}</th>
                  <th scope="col">{{ this.$t('general.title_position') }}</th>
                  <th scope="col">{{ this.$t('general.added_at') }}</th>
                  <th scope="col">Son Giriş Tarihi</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody class="list">
                <template v-for="item in formattedItem">
                  <tr>
                    <th scope="row">
                      <div class="media align-items-center">
                        <input :id="'checkbox_' + item.id" v-if="!existing_users.includes(item.id)"
                          :checked="selectedUsers.some(r => r.id === item.id)" @change="addUser($event, item)"
                          type="checkbox" />
                        <input v-else :checked="true" disabled type="checkbox" class="disabled" />
                      </div>
                    </th>

                    <th scope="row">
                      <div class="media align-items-center">
                        <div>
                          <div class="avatar-parent-child" style="width: max-content">
                            <img alt="Image placeholder" :src="item.photo_link" class="avatar rounded-circle" />
                            <span :class="item.is_online ? 'bg-success' : 'bg-offline'
                              " class="avatar-child avatar-badge"></span>
                          </div>
                        </div>
                        <div class="media-body ml-4">
                          <a @click="setActiveItem(item)" class="name h6 mb-0 text-sm">{{ item.fullname }}</a>
                          <small class="d-block font-weight-bold">#{{ item.id }}</small>
                        </div>
                      </div>
                    </th>
                    <td>{{ item.title }}</td>
                    <td>{{ item.created_at }}</td>
                    <td>{{ item.last_login_at }}</td>
                    <td class="text-right">
                      <!-- Actions -->
                      <div class="actions ml-3">
                        <a @click="setActiveItem(item)" class="btn btn-icon btn-hover btn-sm btn-circle"
                          uk-tooltip="Kullanıcının Profili">
                          <i class="uil-user"></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                </template>
                <!--user item-->
              </tbody>
            </table>
          </div>
        </div>
        <pagination class="mt-3" :pagination="pagination" @changePage="setPage($event)"></pagination>

        <div class="uk-card-header">
          <div class="float-right">
            <button class="uk-button uk-button-outline-default mr-2 small closeButton" type="button">
              <i class="icon-arrow-left"></i> Geri Dön
            </button>
            <button @click="setSelectedUser" class="uk-button uk-button-primary small closeButton" type="button">
              <i class="icon-feather-save"></i> {{ $t("general.save") }}

            </button>
          </div>
        </div>
      </div>
    </div>
    <UserProfile :item="activeItem" :user_detail="formattedUserDetail"></UserProfile>
  </div>
</template>

<script>
import module, {
  BASE_URL,
  GET_ITEMS,
  MODULE_NAME,
  ERROR,
  ITEMS,
} from "@/core/services/store/user.module";

import company_module, {
  ONLINE_USERS,
  MODULE_NAME as COMPANY_MODULE,
} from "@/core/services/store/company.module";
import user_detail_module, {
  GET_ITEM as GET_USER_DETAIL,
  ITEM as USER_DETAIL,
  MODULE_NAME as USER_DETAIL_MODULE,
} from "@/core/services/store/user_detail.module";

import store from "@/core/services";
import moment from "moment";
import Pagination from "@/assets/components/Pagination";
import UserFilter from "@/view/company/User/Filter";
import UserProfile from "@/view/company/User/UserProfile";

const _USER_DETAIL_MODULE = USER_DETAIL_MODULE;
const _USER_MODULE = MODULE_NAME;
const _COMPANY_MODULE = COMPANY_MODULE;

export default {
  name: "AddToGroupModal",
  components: { Pagination, UserFilter, UserProfile },
  props: {
    title: {
      required: false,
      default: "",
    },
    allowed_number: {
      required: false,
      type: Number,
      default: 0,
    },
    existing_users: {
      required: false,
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      pagination: null,
      activeItem: null,
      page: 1,
      per_page: 10,
      sort_by: "id",
      sort_desc: "desc",
      filter: {
        search: null,
        orderBy: "desc",
        sort: "id",
      },
      selectedUsers: [],
      type: true,
      selectAllText: "Tümünü Seç",
      keepFilter: null,
      company_id: '',
      department_id: '',
      region_id: '',
      title_id: '',
      position_id: '',
      ids: [],

    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_USER_MODULE, module);
    registerStoreModule(_COMPANY_MODULE, company_module);
    registerStoreModule(_USER_DETAIL_MODULE, user_detail_module);
  },
  computed: {
    user_detail: {
      get() {
        return store.getters[USER_DETAIL_MODULE + "/" + USER_DETAIL];
      },
      set(value) { },
    },
    formattedUserDetail() {
      if (this.user_detail === null) return null;
      return this.user_detail;
    },
    onlineUsers() {
      return store.getters[_COMPANY_MODULE + "/" + ONLINE_USERS];
    },
    items: {
      get() {
        return store.getters[_USER_MODULE + "/" + ITEMS];
      },
      set(value) { },
    },
    errors: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
    formattedItem() {
      if (this.items === null || !("data" in this.items)) return [];
      let results = [];
      this.pagination = this.items;
      this.items.data.forEach((item) => {
        results.push({
          id: item.id,
          name: item.name,
          surname: item.surname,
          dob: item.dob ? item.dob : "-",
          email: item.email,
          phone: item.phone,
          photo_link: item.photo_link,
          is_online: this.onlineUsers.find(
            (onlineUser) => onlineUser.user == item.id && onlineUser.status
          )
            ? true
            : false,
          fullname: [item.name, item.surname].join(" "),
          title: item.title ? item.title.name : "-",
          created_at: item.created_at
            ? moment(item.created_at).format("DD/MM/YYYY HH:mm:ss")
            : "-",
          last_login_at: item.last_login_at
            ? moment(item.last_login_at).format("DD/MM/YYYY HH:mm:ss")
            : "-",
        });
      });

      return results;
    },
  },
  methods: {
    getUserDetail(id) {
      store.dispatch(USER_DETAIL_MODULE + "/" + GET_USER_DETAIL, {
        url: "api/user/detail-admin" + "/" + id,
      });
    },
    setActiveItem(item) {
      this.activeItem = item;
      UIkit.modal("#modal-profile").show();
    },
    getFilter(value) {
      console.log(value);
      this.filterResult(value);
    },
    filterResult(filter = null) {
      let self = this;
      if (filter) {
        this.keepFilter = filter;
        this.company_id = filter.company_id;
        this.branch_id = filter.branch_id;
        this.department_id = filter.department_id;
        this.region_id = filter.region_id;
        this.title_id = filter.title_id;
        this.position_id = filter.position_id;
      } else {
        filter = this.keepFilter;
      }
      let filters = {
        like: self.filter.search,
        page: self.page,
        per_page: self.per_page,
        order: self.filter.orderBy,
        sort: self.filter.sort,
      };
      if (filter) {
        filter.company_id ? filters['company_id'] = filter.company_id : null;
        filter.company_id = null;
        $.each(filter, function (key, value) {
          if (value && Array.isArray(filter[key])) {
            let ids = [];
            filter[key]?.forEach((items) => {
              ids.push(items.id);
            });
            if (key === 'assignment_id') {
              filter?.assignment_id?.every((assignmentId) => {
                if (assignmentId.id === null) {
                  ids = null
                  return false
                } else {
                  return true
                }
              })
            }
            filters[key] = JSON.stringify(ids)
          }
        });
      }
      this.$store.dispatch(MODULE_NAME + "/" + GET_ITEMS, {
        url: BASE_URL,
        filters: filters,
      }).then((response) => {
        console.log(response.data)
        response.data.forEach((responseData) => {
          let flag = true;
          this.selectedUsers.forEach((selectedUsers) => {
            if (responseData.id === selectedUsers.id) {
              flag = false;
            }
          })
          if (flag) {
            this.selectAllText = "Tümünü Seç";
            this.type = true;
          }
        })
        console.log(this.selectedUsers)
      });
      // UIkit.dropdown("#filterDropdown").hide();
    },
    addUser(eve, user) {
      let users = this.selectedUsers;
      let index = users
        .map((u) => {
          return u.id;
        })
        .indexOf(user.id);

      if (index === -1) {
        if (this.allowed_number == 0 || users.length < this.allowed_number)
          users.push(user);
        else eve.target.checked = false;
      } else users.splice(index, 1);
    },
    sortOrder(orderBy, sort) {
      this.page = 1;
      this.filter.orderBy = orderBy;
      this.filter.sort = sort;
      debgugger;
      this.filterResult();
      UIkit.dropdown(this.$refs["filterRef"]).hide(0);
    },
    setPage(page) {
      this.page = page;
      this.filterResult();
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    allToggle(val) {
      if (val) {
        this.formattedItem.forEach((item) => {
          if (
            (this.allowed_number != 0 &&
              this.selectedUsers.length >= this.allowed_number) ||
            this.existing_users.includes(item.id)
          )
            return false;
          this.addUser(null, item);
          $("#checkbox_" + item.id).prop("checked", true);
          this.selectAllText = "Tümünü Kaldır";
          this.type = false;
        });
      } else {
        this.formattedItem.forEach((item) => {
          $("#checkbox_" + item.id).prop("checked", false);
          this.selectedUsers.forEach((data, index) => {
            if (data.id === item.id) {
              this.selectedUsers.splice(index, 1);
            }
          })
        });

        // this.selectedUsers = [];
        this.selectAllText = "Tümünü Seç";
        this.type = true;
      }
    },
    setSelectedUser() {
      this.$emit("getList", this.selectedUsers);
      this.allToggle(false);
      this.selectedUser = [];
      $();
    },
  },
  mounted() {
    this.filterResult();
    this.debouncedFilterSearch = _.debounce(this.filterResult, 500);
    $(".selectpicker").selectpicker();

    // $(".closeButton").click(function () {
    //   $(".uk-close").click();
    // });
  },
  beforeDestroy() {
    const modal = UIkit.modal("#modal-profile");
    if (modal) modal.$destroy(true);
  },
};
</script>

<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.uk-alert {
  margin: 0;
}
</style>
