<template>
  <div>
    <!-- Birthday Modal -->
    <div class="modal fade" style="text-align:center;" :class="{ show: isActive, displayBlock: displayBlock }"
      id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content" style="background-color:rgba(0, 0, 0, 0); border:none">
          <div class="modal-body" style="align-self:center;">
            <div class="birthdaycard">
              <div class="cardfront">
                <h3 class="happy">Mutlu Yıllar !</h3>

                <div class="balloons">

                  <div class="balloon1"></div>

                  <div class="balloon2"></div>

                  <div class="balloon3"></div>

                  <div class="balloon4"></div>

                </div><!-- end balloons -->

              </div><!-- end cardfront -->
              <div class="cardinside">
                <h3 class="back">Mutlu Yıllar</h3>
                <p>Merhaba {{ authUser.fullname }},</p>
                <p>Doğum gününüz Kutlu Olsun :)</p>
                <p class="name">ANZERA</p>
              </div><!-- end cardinside -->
            </div><!-- end birthdaycard -->
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-info" data-dismiss="modal" @click="closeModal"> {{
              $t("general.close") }}</button>
          </div>
        </div>
      </div>
    </div>

    <!-- LOADING BOX -->
    <default-loading v-if="data_loading" :minHeight="'80vh'"></default-loading>
    <!-- Banner Slider -->
    <div v-show="!data_loading"
      class="uk-position-relative uk-visible-toggle uk-light  uk-slider-container-offset dashboard-banner-slider"
      tabindex="-1" uk-slider>
      <ul id="dashboard" class="uk-slider-items uk-grid">
        <li class="uk-width-5-5" v-for="(item, index) in dashboard_item.advertisements"
          :key="'ad_slider_list_item_' + index">
          <div class="uk-panel" uk-grid>
            <img v-if="item.image_link" :src="item.image_link" width="100%" height="auto" alt="">
            <div class="uk-position-center-left uk-text-left" style="padding-top: 9vw;padding-left: 4vw;">
              <h2 v-if="item.name">{{ item.name }}</h2>
              <p v-if="item.text">{{ item.text }}</p>
              <a v-if="item.url || item.button_text" :href="item.url" :target="item.is_new_tab ? '_blank' : ''"
                class="btn btn-default" style="background-color:  rgba(0, 51,102);">
                {{ item.button_text }}
              </a>
            </div>
          </div>
        </li>
      </ul>
      <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous
        uk-slider-item="previous"></a>
      <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next
        uk-slider-item="next"></a>
    </div>
    <!-- MAIN CONTENT -->
    <div v-show="!data_loading" class="uk-container uk-container-large">
      <div class="section-small">
        <button class="btn switch hide" @click="bannerSlideUpDown"><i class="uil-minus-square-full"></i>
          {{ $t('home.hide_ads') }}
        </button>

        <!-- SEARCH BAR -->
        <searching @searchFor="searchResults"></searching>

        <div class="uk-grid-small uk-child-width-expand@s uk-text-center mb-4" uk-grid>
          <div>
            <div class="course-card uk-card-hover foruser">
              <div class="course-card-body d-flex justify-content-center align-items-center">
                <i class="uil-book-open"></i>
                <div>
                  <h3>{{ dashboard_item.assigned_educations_count }}</h3>
                  <h4>{{ $t('home.education_assigned') }}</h4>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="course-card uk-card-hover foruser">
              <div class="course-card-body d-flex justify-content-center align-items-center">
                <i class="uil-books"></i>
                <div>
                  <h3>{{ dashboard_item.assigned_trainings_count }}</h3>
                  <h4>{{ $t('general.catalog_training_assigned') }}</h4>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="course-card foruser">
              <div class="course-card-body d-flex justify-content-center align-items-center">
                <i class="uil-presentation-check "></i>
                <div>
                  <h3>{{ dashboard_item.completed_educations_count }}</h3>
                  <h4>{{ $t('home.i_completed_education') }} </h4>
                </div>
              </div>
            </div>
          </div>

          <!-- <div>
            <div class="course-card uk-card-hover foruser">
              <div class="course-card-body">
                <i class="uil-bolt-alt"></i>
                <h3>{{ dashboard_item.duels_count }}</h3>
                <h4>Düello Yaptım</h4>
              </div>
            </div>
          </div> -->
          <div v-if="this.$isGiftVisible">
            <div class="course-card foruser">
              <div class="course-card-body d-flex justify-content-center align-items-center">
                <i class="uil-gift"></i>
                <div>
                  <h3>{{ dashboard_item.gifts_count }}</h3>
                  <h4>{{ $t('home.i_won_a_gift') }} </h4>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="course-card foruser uk-card-primary">
              <div class="course-card-body d-flex justify-content-center align-items-center">
                <i class="uil-coins"></i>
                <div>
                  <h3 style="font-size:2.3rem;">{{ dashboard_item.points_count }} / {{ dashboard_item.coins_count }}
                  </h3>
                  <h4>{{ $t('home.point_coin') }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-child-width-expand" uk-grid>
        <div class="banner-container">
          <!-- <div class="uk-card-default rounded uk-card-hover mt-3 p-2">
            <div class="course-card-body">
              <div class="section-header-left">
                <h4 class="uk-text-truncate"><i class="uil-bolt-alt"></i> Düello Davetleri</h4>
              </div>
              <div class="pt-3 pb-1 text-center">

                <button class="duello-test uk-button uk-button-default invisible" type="button" onclick="UIkit.notification({message: '<div class=\'uk-text-small uk-grid-small bg-gradient-success uk-light round shadow-primary uk-padding-small\' style=\'margin: -15px ; padding-left:0px \' uk-grid><div class=\'uk-width-auto\'><img width=\'50\' src=\'@/assets/images/avatars/avatar-1.jpg\' class=\'uk-avatar\' style=\'border-radius:50%\'></div><div class=\'uk-width-expand\'><h5 class=\'uk-margin-remove-adjacent uk-margin-small-bottom\'> Düello davetin var! </h5><p style=\'color:#fff;line-height:1.2\'> <b>Selma Güler</b> seninle düello yapmak istiyor!</p></div></div>', pos: 'bottom-right'})">
                  Click me </button>

                <br>
                <br>
                <br>
                <br>
                <br>
                <ul class="uk-list-divider mt-2 pr-3 challange d-none">
                  <li class="d-flex justify-content-between align-items-center"><a href="#">Erhan
                    Güler</a>
                    <button type="button"
                            class="btn btn-animated btn-primary btn-sm btn-animated-x uk-first-column">
                      <span class="btn-inner--visible">Meydan Oku</span>
                      <span class="btn-inner--hidden">
                                                    <i class="uil-bolt-alt"></i>
                                                </span>
                    </button>
                  </li>
                  <li class="d-flex justify-content-between align-items-center"><a href="#">Erhan
                    Güler</a>
                    <button type="button"
                            class="btn btn-animated btn-primary btn-sm btn-animated-x uk-first-column">
                      <span class="btn-inner--visible">Meydan Oku</span>
                      <span class="btn-inner--hidden">
                                                    <i class="uil-bolt-alt"></i>
                                                </span>
                    </button>
                  </li>
                </ul>
              </div>

              <div class="course-card-footer">
                <a href="duello.html" class="btn btn-secondary btn-sm uk-first-column">
                  Daha Fazla <i class="uil-arrow-right"></i>
                </a>
              </div>
            </div>
          </div> -->
          <div v-if="leaderTableVisible" class="uk-card-default rounded uk-card-hover mt-3">
            <div class="course-card-body">
              <div class="uk-card-header pb-2 pt-0 pl-0 pr-0">

                <div class="section-header-left">
                  <h4 class="uk-text-truncate"><i class="uil-trophy"></i> {{ $t('home.leaderboards') }}</h4>
                </div>
              </div>

              <div class="pt-2 pb-1 text-center">
                <ul id="leadership" class="uk-list-divider mt-2">
                  <li class="yourself">
                    <div class="uk-width-auto d-flex align-items-center">

                      <p class="m-0 line">{{ getCurrentUserPosition() }}</p>
                      <img :src="current_user.photo" class="rounded-circle avatar-sm">
                      <p class="m-0">{{ current_user.fullname }}</p>
                    </div>

                    <span>{{ dashboard_item.points_count }} {{ $t('home.point')
                    }}</span>
                  </li>
                  <template v-for="(user_point, index) in leader_board.result">
                    <li :class="{ active: user_point.id == current_user.id }"
                      class="d-flex justify-content-between align-items-center">
                      <span>
                        <div class="line mr-2">{{ index + 1 }}</div>
                        <img :src="user_point.photo_link" class="rounded-circle avatar-sm">
                        {{
                          user_point.fullname
                        }}
                      </span>
                      <span>{{ user_point.points_sum_point ? user_point.points_sum_point : 0 }} {{ $t('home.point')
                      }}</span>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </div>

          <div v-if="socialWallVisible" class="uk-card-default rounded uk-card-hover mt-3">
            <div class="course-card-body">
              <div class="uk-card-header pb-2 pt-0 pl-0 pr-0">
                <div class="section-header-left">
                  <h4 class="uk-text-truncate"><i class="uil-coffee"></i> {{ $t('home.social_wall') }}</h4>
                </div>
              </div>
              <ul id="social-wall" class="uk-list-divider mt-4">

                <li v-for="(item, index) in last_posts" :key="'last_posts' + index"
                  class="d-flex justify-content-between align-items-center">
                  <router-link :to="{ name: 'user.wall', params: { search_text: item.text } }">
                    <h6 class="d-flex">
                      <i class="uil-user mr-1"></i>
                      <p class="m-0" v-if="item.user">{{ item.user.name }} {{ item.user.surname }} <span>{{
                        $t('home.shared_a_comment') }}</span></p>
                    </h6>
                    <p class="m-0">"{{ item.text }}"</p>
                  </router-link>
                </li>
              </ul>
              <div class="course-card-footer">
                <router-link class="btn btn-secondary btn-sm uk-first-column" to="/wall">
                  {{ $t('home.go_to_my_social_wall') }} <i class="uil-arrow-right"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-5-5@s" v-bind:class="{
          'uk-width-4-4@m': !socialWallVisible && !leaderTableVisible,
          'uk-width-3-4@m': socialWallVisible || leaderTableVisible
        }">


          <template v-for="(item, index) in moduleList?.dashboard">
            <!-- UPCOMING  TRAININGS-->
            <template v-if="item.type === 'upcoming_trainings' && item.status === 1">
              <div class="section-header pb-0 mt-3">
                <div class="section-header-left">
                  <h4 class="uk-text-truncate"><i class="uil-clock-eight"></i> {{ $t('home.my_upcoming_trainings') }}
                  </h4>
                </div>
                <div class="section-header-right">
                  <router-link :to="{ name: 'user.my-assignment' }" class="button transparent uk-visible@m"><i
                      class=" uil-list-ui-alt"></i>
                    {{ $t('home.see_all') }}
                  </router-link>
                </div>
              </div>
              <div class="section-small">
                <div class="uk-slider-container-offset" uk-slider="finite: true">
                  <div class="uk-position-relative uk-visible-toggle">
                    <ul class="uk-slider-items uk-child-width-1-4@m uk-child-width-1-4@s uk-grid">
                      <li v-for="(assigned_education, index) in assigned_educations.data"
                        :key="'assigned_education_item_' + index">
                        <div :key="'assigned_education_item_' + index">
                          <div class="course-path-card mb-2">
                            <router-link :to="{ name: 'user.assignment-detail', params: { id: assigned_education.id } }">
                              <img :src="assigned_education.cover_image_link" alt="" style="width:100%">
                              <div class="course-path-card-contents">
                                <div class="course-card-info d-flex justify-content-between">
                                  <div>
                                    <span class="catagroy">{{
                                      assigned_education.is_compulsory ? $t('assignment.compulsory_education') :
                                      $t('assignment.not_compulsory_education')
                                    }}</span>
                                  </div>
                                  <div v-if="assigned_education.has_present">
                                    <i class="icon-feather-gift"
                                      uk-tooltip="title: Hediyeli Eğitim; pos: bottom-center"></i>
                                  </div>
                                </div>
                                <h3>{{ assigned_education.name }}</h3>
                                <div
                                  :uk-tooltip="'title: %' + assigned_education.completion_percentage + ' Tamamlandı; pos: bottom-center'"
                                  class="course-progressbar mt-3">
                                  <div
                                    :class="[(assigned_education.completion_percentage >= 75 ? 'bg-success' : ''), (assigned_education.completion_percentage < 75 && assigned_education.completion_percentage >= 50 ? 'bg-warning' : '')]"
                                    :style="'width:' + assigned_education.completion_percentage + '%'"
                                    class="course-progressbar-filler"></div>
                                </div>
                                <div class="mt-2">
                                  <p class="date"><i class="icon-feather-calendar mr-1"></i>{{
                                    timeFromDate(assigned_education.end_date) }}
                                  </p>
                                </div>
                              </div>
                              <div class="course-path-card-footer">
                                <div class="star-rating">
                                  <span class="avg"> {{ assigned_education.total_rating }} </span>
                                  <star-rating :increment="star_increment" :max-rating="5" :padding="1"
                                    :rating="assigned_education.total_rating" :read-only="true" :show-rating="false"
                                    :star-points="rating_star_points" :star-size="18"></star-rating>
                                </div>
                              </div>
                            </router-link>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <!-- Slider Buttons -->
                    <a class="uk-position-center-left text-primary uk-position-small uk-hidden-hover" href="#"
                      uk-slidenav-previous uk-slider-item="previous"></a>
                    <a class="uk-position-center-right text-primary uk-position-small uk-hidden-hover" href="#"
                      uk-slidenav-next uk-slider-item="next"></a>
                  </div>
                  <!--  Slider Dot Nav Buttons -->
                  <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
                </div>
              </div>
            </template>
            <!-- HIGHTLIGHTS  TRAININGS-->
            <template v-if="item.type === 'highlights_trainings' && item.status === 1">
              <div class="section-header pb-0 mt-3">
                <div class="section-header-left">
                  <h4 class="uk-text-truncate"><i class="uil-puzzle-piece"></i> {{
                    $t('home.trainings_featured_by_the_institution') }} </h4>
                </div>
                <div class="section-header-right">
                  <router-link :to="{ name: 'user.my-assignment' }" class="button transparent uk-visible@m"><i
                      class=" uil-list-ui-alt"></i>
                    {{ $t('home.see_all') }}
                  </router-link>
                </div>
              </div>
              <div class="section-small">
                <div class="uk-child-width-1-4@m uk-child-width-1-4@s course-card-grid uk-grid-match" uk-grid>
                  <template v-for="(assigned_education, index) in highlight_educations">
                    <div v-if="assigned_education.highlight_education == true"
                      :key="'assigned_education_featured_item_' + index">
                      <div class="course-path-card mb-2">
                        <router-link :to="{ name: 'user.assignment-detail', params: { id: assigned_education.id } }">
                          <img :src="assigned_education.cover_image_link" alt="" style="width:100%">
                          <div class="course-path-card-contents">
                            <div class="course-card-info d-flex justify-content-between">
                              <div>
                                <span class="catagroy">{{
                                  assigned_education.is_compulsory ? $t('assignment.compulsory_education') :
                                  $t('assignment.not_compulsory_education')
                                }}</span>
                              </div>
                              <div v-if="assigned_education.has_present">
                                <i class="icon-feather-gift" uk-tooltip="title: Hediyeli Eğitim; pos: bottom-center"></i>
                              </div>
                            </div>
                            <h3>{{ assigned_education.name }}</h3>
                            <div
                              :uk-tooltip="'title: %' + assigned_education.completion_percentage + ' Tamamlandı; pos: bottom-center'"
                              class="course-progressbar mt-3">
                              <div
                                :class="[(assigned_education.completion_percentage >= 75 ? 'bg-success' : ''), (assigned_education.completion_percentage < 75 && assigned_education.completion_percentage >= 50 ? 'bg-warning' : '')]"
                                :style="'width:' + assigned_education.completion_percentage + '%'"
                                class="course-progressbar-filler"></div>
                            </div>
                            <div class="mt-2">
                              <p class="date"><i class="icon-feather-calendar mr-1"></i>{{
                                timeFromDate(assigned_education.end_date) }}</p>
                            </div>
                          </div>
                          <div class="course-path-card-footer">
                            <div class="star-rating">
                              <span class="avg"> {{ assigned_education.total_rating }} </span>
                              <star-rating :increment="star_increment" :max-rating="5" :padding="1"
                                :rating="assigned_education.total_rating" :read-only="true" :show-rating="false"
                                :star-points="rating_star_points" :star-size="18"></star-rating>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </template>
                </div>
                <hr>
              </div>
            </template>
            <!-- MOST LIKED TRAININGS-->
            <template v-if="item.type === 'most_liked_trainings' && item.status === 1">
              <div class="section-header pb-0 mt-3">
                <div class="section-header-left">
                  <h4 class="uk-text-truncate"><i class="uil-heart"></i> {{ $t('home.most_popular_trainings') }}</h4>
                </div>
                <div class="section-header-right">
                  <router-link :to="{ name: 'user.my-assignment' }" class="button transparent uk-visible@m"><i
                      class=" uil-list-ui-alt"></i>
                    {{ $t('home.see_all') }}
                  </router-link>
                </div>
              </div>
              <div class="section-small">
                <div class="uk-child-width-1-4@m uk-child-width-1-4@s course-card-grid uk-grid-match" uk-grid>
                  <template v-for="(popular_education, index) in popular_educations.data">
                    <div v-if="'popular_education_featured_item_' + index"
                      :key="'popular_education_featured_item_' + index">
                      <div class="course-path-card mb-2">
                        <router-link :to="{ name: 'user.assignment-detail', params: { id: popular_education.id } }">
                          <img :src="popular_education.cover_image_link" alt="" style="width:100%">
                          <div class="course-path-card-contents">
                            <div class="course-card-info d-flex justify-content-between">
                              <div>
                                <span class="catagroy">{{
                                  popular_education.is_compulsory ? $t('assignment.compulsory_education') :
                                  $t('assignment.not_compulsory_education')
                                }}</span>
                              </div>
                              <div v-if="popular_education.has_present">
                                <i class="icon-feather-gift" uk-tooltip="title: Hediyeli Eğitim; pos: bottom-center"></i>
                              </div>
                            </div>
                            <h3>{{ popular_education.name }}</h3>
                            <div
                              :uk-tooltip="'title: %' + popular_education.completion_percentage + ' Tamamlandı; pos: bottom-center'"
                              class="course-progressbar mt-3">
                              <div
                                :class="[(popular_education.completion_percentage >= 75 ? 'bg-success' : ''), (popular_education.completion_percentage < 75 && popular_education.completion_percentage >= 50 ? 'bg-warning' : '')]"
                                :style="'width:' + popular_education.completion_percentage + '%'"
                                class="course-progressbar-filler"></div>
                            </div>
                            <div class="mt-2">
                              <p class="date"><i class="icon-feather-calendar mr-1"></i>{{
                                timeFromDate(popular_education.end_date) }}</p>
                            </div>
                          </div>
                          <div class="course-path-card-footer">
                            <div class="star-rating">
                              <span class="avg"> {{ popular_education.total_rating }} </span>
                              <star-rating :increment="star_increment" :max-rating="5" :padding="1"
                                :rating="popular_education.total_rating" :read-only="true" :show-rating="false"
                                :star-points="rating_star_points" :star-size="18"></star-rating>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <hr>
            </template>
            <!-- EXPIRED  TRAININGS-->
            <template v-if="item.type === 'expired_trainings' && item.status === 1">
              <div class="section-header pb-0 mt-3">
                <div class="section-header-left">
                  <h4 class="uk-text-truncate"><i class="uil-clock-three"></i> {{ $t('home.expired_trainings') }}</h4>
                </div>
                <div class="section-header-right">
                  <router-link :to="{ name: 'user.my-assignment' }" class="button transparent uk-visible@m"><i
                      class=" uil-list-ui-alt"></i>
                    {{ $t('home.see_all') }}
                  </router-link>
                </div>
              </div>
              <div class="section-small">
                <div class="uk-child-width-1-4@m uk-child-width-1-4@s course-card-grid uk-grid-match" uk-grid>
                  <template v-for="(expired_education, index) in expired_educations.data">
                    <div v-if="'expired_education_featured_item_' + index"
                      :key="'expired_education_featured_item_' + index">
                      <div class="course-path-card mb-2">
                        <router-link :to="{ name: 'user.assignment-detail', params: { id: expired_education.id } }">
                          <img :src="expired_education.cover_image_link" alt="" style="width:100%">
                          <div class="course-path-card-contents">
                            <div class="course-card-info d-flex justify-content-between">
                              <div>
                                <span class="catagroy">{{
                                  expired_education.is_compulsory ? $t('assignment.compulsory_education') :
                                  $t('assignment.not_compulsory_education')
                                }}</span>
                              </div>
                              <div v-if="expired_education.has_present">
                                <i class="icon-feather-gift" uk-tooltip="title: Hediyeli Eğitim; pos: bottom-center"></i>
                              </div>
                            </div>
                            <h3>{{ expired_education.name }}</h3>
                            <div
                              :uk-tooltip="'title: %' + expired_education.completion_percentage + ' Tamamlandı; pos: bottom-center'"
                              class="course-progressbar mt-3">
                              <div
                                :class="[(expired_education.completion_percentage >= 75 ? 'bg-success' : ''), (expired_education.completion_percentage < 75 && expired_education.completion_percentage >= 50 ? 'bg-warning' : '')]"
                                :style="'width:' + expired_education.completion_percentage + '%'"
                                class="course-progressbar-filler"></div>
                            </div>
                            <div class="mt-2">
                              <p class="date"><i class="icon-feather-calendar mr-1"></i>{{
                                timeFromDate(expired_education.end_date) }}</p>
                            </div>
                          </div>
                          <div class="course-path-card-footer">
                            <div class="star-rating">
                              <span class="avg"> {{ expired_education.total_rating }} </span>
                              <star-rating :increment="star_increment" :max-rating="5" :padding="1"
                                :rating="expired_education.total_rating" :read-only="true" :show-rating="false"
                                :star-points="rating_star_points" :star-size="18"></star-rating>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <hr>
            </template>

            <!-- SELF REGISTRABLE  TRAININGS-->
            <template v-if="item.type === 'optional_trainings' && item.status === 1">
              <div class="section-header pb-0 mt-3" v-if="selfRegistrableTrainigs.length > 0">
                <div class="section-header-left">
                  <h4 class="uk-text-truncate"><i class="uil-clock-three"></i> {{ $t('general.optional_trainings') }}
                  </h4>
                </div>
                <div class="section-header-right">
                  <router-link :to="{ name: 'user.education-category' }" class="button transparent uk-visible@m"><i
                      class=" uil-list-ui-alt"></i>
                    {{ $t('home.see_all') }}
                  </router-link>
                </div>
              </div>
              <div class="section-small" v-if="selfRegistrableTrainigs.length > 0">
                <div class="uk-child-width-1-4@m uk-child-width-1-4@s course-card-grid uk-grid-match" uk-grid>
                  <template v-for="(selfRegistrableTraining, index) in selfRegistrableTrainigs">
                    <div>
                      <div class="course-path-card mb-2">
                        <router-link :to="{ name: 'user.eduction-detail', params: { id: selfRegistrableTraining.id } }">
                          <img :src="selfRegistrableTraining.cover_image_link" alt="" style="width:100%">
                          <div class="course-path-card-contents">
                            <div class="course-card-info d-flex justify-content-between">
                              <div>
                                <span class="catagroy">{{
                                  selfRegistrableTraining.is_compulsory ? $t('assignment.compulsory_education') :
                                  $t('assignment.not_compulsory_education')
                                }}</span>
                              </div>
                              <div v-if="selfRegistrableTraining.has_present">
                                <i class="icon-feather-gift" uk-tooltip="title: Hediyeli Eğitim; pos: bottom-center"></i>
                              </div>
                            </div>
                            <h3>{{ selfRegistrableTraining.name }}</h3>
                            <div
                              :uk-tooltip="'title: %' + selfRegistrableTraining.completion_percentage + ' Tamamlandı; pos: bottom-center'"
                              class="course-progressbar mt-3">
                              <div
                                :class="[(selfRegistrableTraining.completion_percentage >= 75 ? 'bg-success' : ''), (selfRegistrableTraining.completion_percentage < 75 && selfRegistrableTraining.completion_percentage >= 50 ? 'bg-warning' : '')]"
                                :style="'width:' + selfRegistrableTraining.completion_percentage + '%'"
                                class="course-progressbar-filler"></div>
                            </div>
                            <div class="mt-2">
                              <p class="date"><i class="icon-feather-clock mr-1"></i>{{
                                timeFromDate(selfRegistrableTraining.created_at) }}</p>
                            </div>
                          </div>
                          <div class="course-path-card-footer">
                            <div class="star-rating">
                              <span class="avg"> {{ selfRegistrableTraining.total_rating }} </span>
                              <star-rating :increment="star_increment" :max-rating="5" :padding="1"
                                :rating="selfRegistrableTraining.total_rating" :read-only="true" :show-rating="false"
                                :star-points="rating_star_points" :star-size="18"></star-rating>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </template>
                </div>
                <hr>
              </div>
            </template>
            <!-- COLLECTIONS-->
            <template v-if="item.type === 'collections' && item.status === 1">
              <div v-if="CollectionVisible" class="course-grid-slider" uk-slider="finite: true">
                <div class="grid-slider-header">
                  <div class="section-header">
                    <h4 class="uk-text-truncate"><i class="uil-notebooks"></i> {{ $t('home.my_latest_collections') }}
                    </h4>
                  </div>
                  <div class="grid-slider-header-link">
                    <router-link :to="{ name: 'user.my-collection' }" class="button transparent uk-visible@m">
                      {{ $t('home.all_my_collections') }}
                    </router-link>
                    <a class="slide-nav-prev" href="#" uk-slider-item="previous"></a>
                    <a class="slide-nav-next" href="#" uk-slider-item="next"></a>
                  </div>
                </div>
                <ul id="book-popular"
                  class="uk-slider-items uk-child-width-1-3@m uk-child-width-1-3@s uk-grid-small uk-grid">
                  <li v-for="(collection, index) in collections" :key="'collection_index_' + index">
                    <router-link :to="{ name: 'user.collection-detail', params: { id: collection.id } }">
                      <div class="book-popular-card">
                        <img :alt="collection.name" :src="collection.cover_image_link" class="cover-img"
                          style="height:110px;">
                        <div class="book-details">
                          <a href="#">
                            <h4>{{ collection.name }}</h4>
                          </a>
                          <p><i class="icon-feather-star"></i> {{ collection.user.name }} {{ collection.user.surname }}
                          </p>
                        </div>
                      </div>
                    </router-link>
                  </li>
                </ul>
                <hr>
              </div>
            </template>
            <!-- PROVIDER TRAININGS-->
            <template
              v-if="item.type === 'linkedin-learning' && item.status === 1 && linkedin_contents?.data?.length > 0">
              <div class="section-header pb-0 mt-3">
                <div class="section-header-left">
                  <h4 class="uk-text-truncate"><i class="uil-puzzle-piece"></i> {{ $t('home.provider_trainings') }}</h4>
                </div>
                <div class="section-header-right">
                  <router-link :to="{ name: 'user.providers-trainings', params: { provider: 'linkedin' } }" class="button transparent uk-visible@m"><i
                      class=" uil-list-ui-alt"></i>
                    {{ $t('home.see_all') }}
                  </router-link>
                </div>
              </div>
              <div class="section-small">
                <div class="uk-child-width-1-4@m uk-child-width-1-4@s course-card-grid uk-grid-match" uk-grid>
                  <template v-for="(provider_item, index) in linkedin_contents.data">
                    <div v-if="'provider_item_' + index" :key="'provider_item_' + index">
                      <div class="course-path-card mb-2 uk-link" @click="openProviderDetails(provider_item)">
                        <img :src="provider_item.image" alt="" style="width:100%">
                        <div class="course-path-card-contents">
                          <div class="course-card-info d-flex justify-content-between">
                            <div>
                              <span class="catagroy">{{ provider_item.provider }}</span>
                            </div>
                          </div>
                          <h3>{{ provider_item.title }}</h3>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <hr>
            </template>

            <template
                v-if="item.type === 'udemy-business' && item.status === 1 && udemy_contents?.data?.length > 0">
              <div class="section-header pb-0 mt-3">
                <div class="section-header-left">
                  <h4 class="uk-text-truncate"><i class="uil-puzzle-piece"></i> Udemy</h4>
                </div>
                <div class="section-header-right">
                  <router-link :to="{ name: 'user.providers-trainings', params: { provider: 'udemy' } }" class="button transparent uk-visible@m"><i
                      class=" uil-list-ui-alt"></i>
                    {{ $t('home.see_all') }}
                  </router-link>
                </div>
              </div>
              <div class="section-small">
                <div class="uk-child-width-1-4@m uk-child-width-1-4@s course-card-grid uk-grid-match" uk-grid>
                  <template v-for="(provider_item, index) in udemy_contents.data">
                    <div v-if="'provider_item_' + index" :key="'provider_item_' + index">
                      <div class="course-path-card mb-2 uk-link" @click="openProviderDetails(provider_item)">
                        <img :src="provider_item.image" alt="" style="width:100%">
                        <div class="course-path-card-contents">
                          <div class="course-card-info d-flex justify-content-between">
                            <div>
                              <span class="catagroy">{{ provider_item.provider }}</span>
                            </div>
                          </div>
                          <h3>{{ provider_item.title }}</h3>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <hr>
            </template>
          </template>

        </div>
      </div>


      <!-- course card resume sliders  -->
      <!--<div class="section-small">

          <div uk-slider="finite: true" class="course-grid-slider">

              <div class="grid-slider-header">
                  <div>
                      <h4 class="uk-text-truncate"><i class="uil-books"></i> En Yeni Katalog Eğitimleri</a>
                      </h4>
                  </div>
                  <div class="grid-slider-header-link">
                      <a href="courses.html" class="button transparent uk-visible@m"> Tüm Katalog Eğitimleri</a>
                      <a href="#" class="slide-nav-prev" uk-slider-item="previous"></a>
                      <a href="#" class="slide-nav-next" uk-slider-item="next"></a>
                  </div>
              </div>

              <ul
                  class="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-5@m uk-grid">
                  <li>
                      <a href="course-lesson-1.html">
                          <div class="course-card-resume">
                              <div class="course-card-resume-thumbnail">
                                  <img src="@/assets/images/course/1.png">
                              </div>
                              <div class="course-card-resume-body">
                                  <h5>Eğitim Adı Buraya Geliyor Deneme 123 Test</h5>
                                  <span class="number"> 3 bölüm / 20 içerik </span>
                              </div>
                          </div>
                      </a>
                  </li>
                  <li>
                      <a href="course-lesson-1.html">
                          <div class="course-card-resume">
                              <div class="course-card-resume-thumbnail">
                                  <img src="@/assets/images/course/1.png">
                              </div>
                              <div class="course-card-resume-body">
                                  <h5>Eğitim Adı Buraya Geliyor Deneme 123 Test</h5>
                                  <span class="number"> 3 bölüm / 20 içerik </span>
                              </div>
                          </div>
                      </a>
                  </li><li>
                      <a href="course-lesson-1.html">
                          <div class="course-card-resume">
                              <div class="course-card-resume-thumbnail">
                                  <img src="@/assets/images/course/1.png">
                              </div>
                              <div class="course-card-resume-body">
                                  <h5>Eğitim Adı Buraya Geliyor Deneme 123 Test</h5>
                                  <span class="number"> 3 bölüm / 20 içerik </span>
                              </div>
                          </div>
                      </a>
                  </li><li>
                      <a href="course-lesson-1.html">
                          <div class="course-card-resume">
                              <div class="course-card-resume-thumbnail">
                                  <img src="@/assets/images/course/1.png">
                              </div>
                              <div class="course-card-resume-body">
                                  <h5>Eğitim Adı Buraya Geliyor Deneme 123 Test</h5>
                                  <span class="number"> 3 bölüm / 20 içerik </span>
                              </div>
                          </div>
                      </a>
                  </li><li>
                      <a href="course-lesson-1.html">
                          <div class="course-card-resume">
                              <div class="course-card-resume-thumbnail">
                                  <img src="@/assets/images/course/1.png">
                              </div>
                              <div class="course-card-resume-body">
                                  <h5>Eğitim Adı Buraya Geliyor Deneme 123 Test</h5>
                                  <span class="number"> 3 bölüm / 20 içerik </span>
                              </div>
                          </div>
                      </a>
                  </li><li>
                      <a href="course-lesson-1.html">
                          <div class="course-card-resume">
                              <div class="course-card-resume-thumbnail">
                                  <img src="@/assets/images/course/1.png">
                              </div>
                              <div class="course-card-resume-body">
                                  <h5>Eğitim Adı Buraya Geliyor Deneme 123 Test</h5>
                                  <span class="number"> 3 bölüm / 20 içerik </span>
                              </div>
                          </div>
                      </a>
                  </li><li>
                      <a href="course-lesson-1.html">
                          <div class="course-card-resume">
                              <div class="course-card-resume-thumbnail">
                                  <img src="@/assets/images/course/1.png">
                              </div>
                              <div class="course-card-resume-body">
                                  <h5>Eğitim Adı Buraya Geliyor Deneme 123 Test</h5>
                                  <span class="number"> 3 bölüm / 20 içerik </span>
                              </div>
                          </div>
                      </a>
                  </li><li>
                      <a href="course-lesson-1.html">
                          <div class="course-card-resume">
                              <div class="course-card-resume-thumbnail">
                                  <img src="@/assets/images/course/1.png">
                              </div>
                              <div class="course-card-resume-body">
                                  <h5>Eğitim Adı Buraya Geliyor Deneme 123 Test</h5>
                                  <span class="number"> 3 bölüm / 20 içerik </span>
                              </div>
                          </div>
                      </a>
                  </li><li>
                      <a href="course-lesson-1.html">
                          <div class="course-card-resume">
                              <div class="course-card-resume-thumbnail">
                                  <img src="@/assets/images/course/1.png">
                              </div>
                              <div class="course-card-resume-body">
                                  <h5>Eğitim Adı Buraya Geliyor Deneme 123 Test</h5>
                                  <span class="number"> 3 bölüm / 20 içerik </span>
                              </div>
                          </div>
                      </a>
                  </li>

              </ul>

          </div>

      </div>-->
      <div id="footer"></div>
      <!--<div id="announcement-push" class="announcement-push" uk-offcanvas="flip: true; mode: slide">
           <div class="uk-offcanvas-bar btn-primary"> <button class="uk-offcanvas-close" type="button" uk-close></button>
               <h4><i class="uil-megaphone"></i> Duyurular</h4>
               <div class="pt-3 pb-1">
                   <ul class="uk-list-divider mt-2 pr-3">
                       <li>
                           <a class="d-flex" href="duyurular.html">
                               <h5><span class="nav-tag">Yeni</span> Yeni Yıl Hedeflerimiz</h5>
                           <span>25/01/2021</span>
                           </a>
                       </li>
                       <li>
                           <a class="d-flex" href="duyurular.html">
                               <h5><span class="nav-tag">Yeni</span> Duyuru Başlığı Buraya Gelecek Uzun Başlık Deneme</h5>
                           <span>25/01/2021</span>
                           </a>
                       </li>
                       <li>
                           <a class="d-flex" href="duyurular.html">
                               <h5>Gelişim Kataloğuna Katılım Tarihleri Belirlendi</h5>
                           <span>25/01/2021</span>
                           </a>
                       </li>
                       <li>
                           <a class="d-flex" href="duyurular.html">
                               <h5>Çalışanlarımızın Dikkatine!</h5>
                           <span>25/01/2021</span>
                           </a>
                       </li>
                       <li>
                           <a class="d-flex" href="duyurular.html">
                               <h5>Gelişim Kataloğuna Katılım Tarihleri Belirlendi</h5>
                           <span>25/01/2021</span>
                           </a>
                       </li>
                       <li>
                           <a class="d-flex" href="duyurular.html">
                               <h5>Çalışanlarımızın Dikkatine!</h5>
                           <span>25/01/2021</span>
                           </a>
                       </li>
                   </ul>
                   <a href="duyurular.html" class="btn btn-info btn-xl uk-first-column d-block mt-3">Daha Fazla <i class="uil-arrow-right"></i></a>
               </div>
           </div>
       </div>

       <button id="btn-announcement" class="btn btn-primary uk-button uk-button-default uk-margin-small-right" type="button" uk-toggle="target: #announcement-push"><i class="uil-megaphone"></i> Duyurular <span>2</span></button>
   -->
    </div>
    <div id="informationForUser" class="uk-modal" uk-modal>
      <div class="uk-modal-dialog bggray">
        <div class="uk-padding-small" uk-grid>
          <h4>{{ $t('general.clarification_Text') }}</h4>
        </div>
        <div class="row p-4">
          <div class="col-lg-12">
            <div class="form-group row mb-2">
              Anzera Öğrenme Teknolojileri A.Ş. Kişisel Verilerin Korunması ve İşlenmesi Aydınlatma Metni <br>
              <hr>
              Bu aydınlatma metni, siz değerli kullanıcılarımızın Anzera Öğrenme Teknolojileri A.Ş.tarafından sağlanan
              hizmetlerimizi kullanırken kişisel verilerinizin nasıl işlendiği konusunda sizi bilgilendirmek amacıyla
              hazırlanmıştır. [Anzera Öğrenme Teknolojileri A.Ş.], Kişisel Verilerin Korunması Kanunu ("KVKK")
              hükümlerine tam uyum göstermekte ve kişisel verilerinizi gizli ve güvenli bir şekilde işlemek için gerekli
              önlemleri almaktadır.<br>
              1. Kişisel Verilerinizin İşlenme Amacı:
              Anzera Öğrenme Teknolojileri A.Ş., sunduğumuz hizmetleri sağlayabilmek, sizlere özel teklifler sunmak,
              pazarlama iletişimi yapmak, müşteri memnuniyetini artırmak, istatistiksel analizler yapmak gibi amaçlarla
              kişisel verilerinizi işleyebilir.<br>
              2. İşlenen Kişisel Verileriniz:
              Yukarıda bahsedilen amaçlarla işlediğimiz kişisel verileriniz aşağıdakileri içerebilir:<br>
              - İsim, adres, telefon numarası gibi temel iletişim bilgileri<br>
              - E-posta adresi, kullanıcı adı ve şifre gibi kimlik bilgileri<br>
              - Anzera Öğrenme Teknolojileri A.Ş.'deki etkileşimlerinizi takip etmek için çerezler ve benzeri
              teknolojiler<br>
              3. Kişisel Verilerinizin İşlenme Hukuki Sebebi:
              Kişisel verilerinizin işlenmesi, size sağlanan hizmetleri sunmak ve kanunen gerekliliklerini yerine
              getirmek, Anzera Öğrenme Teknolojileri A.Ş.'nin meşru çıkarlarını koruma amacıyla
              gerçekleştirilebilir.<br>
              4. Kişisel Verilerin Aktarımı:
              Kişisel verileriniz, yasal düzenlemeler gerektirdiği durumlarda veya hizmet sağlayıcılarımızla işbirliği
              yaptığımızda belirli koşullar altında paylaşılabilir. Bu durumda, verilerinizin gizliliği ve güvenliği
              için gerekli önlemlerin alındığından emin olacağız.<br>
              5. Kişisel Verilerinizin Saklanması:
              Kişisel verileriniz, KVKK ve ilgili mevzuata uygun olarak belirlenen süre boyunca saklanacaktır. Saklama
              süresi dolduğunda, kişisel verileriniz silinecek veya yok edilecektir.<br>
              6. Kişisel Veri Sahibi Hakları:
              KVKK kapsamında, kişisel veri sahipleri olarak aşağıdaki haklara sahipsiniz:<br>
              - Kişisel verilerinizin işlenip işlenmediğini öğrenme,<br>
              - İşlenen kişisel verilerinizi talep etme,<br>
              - İşlenen kişisel verilerinizin düzeltilmesini isteme,<br>
              - İşlenen kişisel verilerinizin silinmesini veya yok edilmesini isteme,<br>
              - İşlenen kişisel verilerinizin aktarıldığı üçüncü tarafların bilgilendirilmesini isteme,<br>
              - İşlenen kişisel verilerinize karşı bir itirazda bulunma,<br>
              - Otomatik sistemlerle işlenen kişisel verilerinizle ilgili bir kararın size karşı oluşturulmasını
              engelleme.<br>
              7. İletişim Bilgileri:
              Verilerin işlenmesi veya KVKK konusundaki sorularınızı sormak için lütfen aşağıdaki iletişim bilgilerini
              kullanın:<br>
              Anzera Öğrenme Teknolojileri A.Ş.<br>
              Büyükdere Cad. Onur İş Merkezi No:1/1 Kat:6  34394 Esentepe – Şişli/İstanbul<br>
              0212 267 53 73<br>
              info@anzera.com.tr<br>
              Bu aydınlatma metni, kişisel verilerinizin gizliliği ve güvenliği hakkında daha fazla bilgi sağlamaktadır.
              Herhangi bir sorunuz veya endişeniz varsa, lütfen bizimle iletişime geçmekten çekinmeyin.<br>
              Anzera Öğrenme Teknolojileri A.Ş. kişisel verilerinizi korumak ve gizliliğinizi sağlamak için gerekli tüm
              önlemleri almaktadır. Daha fazla bilgi için Gizlilik Politikamızı inceleyebilirsiniz.
            </div>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right bggray">
          <button class="uk-button uk-button-default small uk-modal-close" type="button">
            <i class="uil-times"></i> {{ $t("general.close") }}
          </button>
        </div>
      </div>
    </div>
    <div id="informationForNext4biz" class="uk-modal" uk-modal>
      <div class="uk-modal-dialog bggray">
        <div class="uk-padding-small" uk-grid>
          <h4>Aydınlatma Metni</h4>
        </div>
        <div class="row p-4">
          <div class="col-lg-12">
            <div class="form-group row mb-2">
              Next4biz BİLGİ TEKNOLOJİLERİ A.Ş. KİŞİSEL VERİLERİN İŞLENMESİ AYDINLATMA METNİ<br>
              <hr>
              <div class="legacy__page-content_detail">
                <h3>Next4biz BİLGİ TEKNOLOJİLERİ A.Ş. KİŞİSEL VERİLERİN İŞLENMESİ AYDINLATMA METNİ</h3>
                <p>Next4biz Bilgi Teknolojileri A.Ş. (“Şirket”) olarak kişisel verilerinizin güvenliği hususuna azami
                  özen
                  gösterilmekte, ürün ve hizmetlerimizden faydalanan kişiler dahil, tüm şahıslara ait her türlü kişisel
                  veriler 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVK Kanunu”)’na uygun olarak işlenerek,
                  muhafaza
                  edilmektedir. KVK Kanunu’nda tanımlı şekli ile “Veri Sorumlusu” sıfatıyla, kişisel verilerinizi
                  aşağıda
                  izah edildiği surette ve mevzuat tarafından emredilen sınırlar çerçevesinde işlemekteyiz.</p>
                <p><strong>1.Kişisel Verilerin İşlenmesi Amaçları</strong></p>
                <p>Şirketimiz tarafından sağlanan hizmetler ve Şirketimizin ticari faaliyetlerine bağlı olarak ve bu iş
                  ve
                  hizmetlerin sunulabilmesi amacıyla kişisel verileriniz işlenmektedir.</p>
                <p>Toplanan kişisel verileriniz, Şirketimiz tarafından sunulan ürün ve hizmetlerden sizleri
                  faydalandırmak
                  için gerekli çalışmaların iş birimlerimiz tarafından yapılması, Şirketimiz tarafından sunulan ürün ve
                  hizmetlerin sizlerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek sizlere
                  önerilmesi, Şirketimizin, Şirketimizle iş ilişkisi içerisinde olan kişilerin hukuki ve ticari
                  güvenliğinin
                  temini, müşterileri değerlendirme/şikayet yönetimi süreçleri, itibar araştırma süreçleri, etkinlik
                  yönetimi, hukuki uyum süreci, denetim, mali işler v.b., Şirketimizin ticari ve iş stratejilerinin
                  belirlenmesi ve uygulanması ve Şirketimizin insan kaynakları politikalarının yürütülmesinin temini
                  amaçlarıyla KVK Kanunu’nun 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları
                  dahilinde işlenecektir.</p>
                <p><strong>2.İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği</strong></p>
                <p>Operasyonel süreçlerin yürütülmesi kapsamında kişisel veriler aktarılmamaktadır. Şirketimizin sunduğu
                  hizmetlerin gerektirdiği hallerde ve zorunlu olan ölçüde üçüncü parti iş ortaklarımızın veri
                  merkezlerinde, güvenli ortamlarda verilerin saklanması amacıyla kişisel verileriniz yurt içinde
                  işlenecektir.</p>
                <p><strong>3.Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi</strong></p>
                <p>Şirketimiz hizmetlerini almak amacıyla firmanızın ve sizin sözleşme ilişkisine girmeniz, destek
                  merkezlerimizi veya internet sayfamızı kullanmanız, Şirketimizi veya internet sitemizi ziyaret
                  etmeniz,
                  Şirketimizin düzenlediği eğitim, seminer veya organizasyonlara katılmanız halinde dijital ve manuel
                  yöntemler ile toplanmaktadır. Kişisel verileri toplamamızın hukuki sebebi Kanunun 5. Maddesinde
                  gösterilen
                  iş ve hizmet ilişkisinin gereği olan edimin ifası ve yine Kanunun 5. ve 6. maddelerinde belirtilen
                  ilgili
                  kişinin açık rızasıdır.</p>
                <p><strong>4.Kişisel Veri Sahibinin KVK Kanunu’nun 11. maddesinde Sayılan Hakları</strong></p>
                <p>Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi, işbu Aydınlatma Metninde aşağıda
                  düzenlenen yöntemlerle Şirketimize iletmeniz durumunda Şirketimiz talebin niteliğine göre en kısa
                  sürede
                  ve en geç otuz gün içinde talebiniz ücretsiz olarak sonuçlandıracaktır. Ancak, Kişisel Verileri Koruma
                  Kurulunca bir ücret öngörülmesi halinde, Şirketimiz tarafından belirlenen tarifedeki ücret
                  alınacaktır. Bu
                  kapsamda kişisel veri sahipleri;</p>
                <ul>
                  <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
                  <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
                  <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
                  </li>
                  <li>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
                  <li>Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu
                    kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                  <li>KVK Kanunu’nun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen,
                    işlenmesini
                    gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini
                    isteme
                    ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
                  </li>
                  <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin
                    kendisi
                    aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
                  <li>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın
                    giderilmesini talep etme haklarına sahiptir.</li>
                </ul>
                <p>KVK Kanunu’nun 13. maddesinin 1. fıkrası gereğince, yukarıda belirtilen haklarınızı kullanmak ile
                  ilgili
                  talebinizi, yazılı ve imzalı olarak olarak: Next4biz Bilgi Teknolojileri A.Ş., Sahrayı Cedit mah. Halk
                  Sk.
                  No:40/4 Kat 4 PakPen Plaza Kadıköy İstanbul adresine veya <a href="mailto:next4biz@hs03.kep.tr"
                    target="_blank" rel="noopener">next4biz@hs03.kep.tr</a> kayıtlı elektronik posta adresimize güvenli
                  elektronik imza ile imzalanmış olarak gönderebilirsiniz. Kişisel veri sahibi olarak sahip olduğunuz ve
                  yukarıda belirtilen haklarınızı kullanmak için yapacağınız ve kullanmayı talep ettiğiniz hakka ilişkin
                  açıklamalarınızı içeren başvuruda; talep ettiğiniz hususun açık ve anlaşılır olması, talep ettiğiniz
                  konunun şahsınız ile ilgili olması veya başkası adına hareket ediyor iseniz bu konuda özel olarak
                  yetkili
                  olmanız ve yetkinizi belgelendirilmesi, ayrıca KVKK talebiniz konusunda kullanılmak
                  üzere&nbsp;iletişim&nbsp;bilgilerini içermesi ve başvuruya kimliğinizi tevsik edici belgelerin
                  eklenmesi
                  gerekmektedir.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right bggray">
          <button class="uk-button uk-button-default small uk-modal-close" type="button">
            <i class="uil-times"></i> {{ $t("general.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardLayout from "@/view/layouts/DashboardLayout";
import CardInformation from "@/view/components/index/CardInformation";
import EducationCard from "@/view/components/index/EducationCard";
import Collection from "@/view/components/index/Collection";
import DuelInvitations from "@/view/layouts/index/DuelInvitations";
import LiderTable from "@/view/layouts/index/LiderTable";
import Duver from "@/view/layouts/index/Duver";
import Searching from "@/view/components/index/Searching";
import { GET_ITEMS, LOADING } from "@/core/services/store/REST.module";
import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/az';
import 'moment/locale/tr';
import 'moment/locale/en-gb';
import StarRating from "vue-star-rating";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import { mapGetters } from "vuex";
import SecureLS from "secure-ls";
import axios from "axios";

const ls = new SecureLS({ encodingType: 'aes', isCompression: true });

export default {
  name: "dashboardIndexComponent",
  components: {
    DashboardLayout,
    CardInformation,
    EducationCard,
    Collection,
    DuelInvitations,
    LiderTable,
    Duver,
    Searching,
    StarRating,
    DefaultLoading,
    SecureLS
  },
  data() {
    return {
      slider_display: false,
      assignedEducationsUrl: 'api/assignment',
      providersAssignmentsUrl: 'api/provider-search',
      collectionUrl: 'api/collection',
      dashboardUrl: 'api/dashboard',
      leaderBoardUrl: 'api/dashboard/leader-board',
      lastPostsUrl: 'api/last-posts',
      selfRegistrableUrl: 'api/self-registrable-trainings',
      assigned_educations: [],
      highlight_educations: [],
      popular_educations: [],
      expired_educations: [],
      udemy_contents: [],
      linkedin_contents: [],
      collections: [],
      dashboard_item: [],
      current_user: [],
      leader_board: [],
      last_posts: [],
      filters: {
        search: null
      },
      rating_star_points: [23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46, 19, 31, 17,],
      star_increment: 0.1,
      isActive: false,
      displayBlock: false,
      selfRegistrableTrainigs: [],
      subdomain: null,
      moduleList: [],
      leaderTableVisible: true,
      socialWallVisible: true
    };
  },
  computed: {
    ...mapGetters({
      data_loading: LOADING
    }),
  },
  methods: {
    closeModal() {
      this.isActive = false
      this.displayBlock = false
    },
    birthDayModal() {
      const today = new Date()
      var inputDate = new Date(this.authUser.dob);
      if (!ls.get('userBirthday')) {
        if (moment(inputDate).format('DD MMMM') == moment(today).format('DD MMMM')) {
          this.isActive = true
          this.displayBlock = true
          ls.set('userBirthday', true)
        } else {
          this.isActive = false
          this.displayBlock = false
        }
      }
    },
    bannerSlideUpDown(event) {
      let button = event.target;
      if (button.classList.contains('hide')) {
        $('div.dashboard-banner-slider').slideUp();
        button.classList.remove("hide");
        button.classList.add('show');
        button.innerHTML = "<i class='uil-square-full'></i>" + this.$t("general.show_ads");
      } else if (button.classList.contains('show')) {
        $('div.dashboard-banner-slider').slideDown();
        button.classList.remove("show");
        button.classList.add('hide');
        button.innerHTML = "<i class='uil-minus-square-full'></i>" + this.$t("home.hide_ads");
      }
    },
    getAssignedEducations() {
      let self = this;

      let filter = {
        page: 1,
        perPage: 15,
        order_by: 'end_date',
        sort: 'asc',
        by_user: true,
        with_expired: false,
        search: this.filters.search,
        only_on_progress: false,
        up_coming_trainings: true
      }
      self.$store.dispatch(GET_ITEMS, {
        url: self.assignedEducationsUrl,
        acceptPromise: false,
        showLoading: true,
        filters: filter
      }).then(result => {
        if (result.status) {
          this.assigned_educations = result.data
        }
      })
    },
    getHighlightEducations() {
      let self = this;
      let filter = {
        page: 1,
        perPage: 4,
        order_by: 'created_at',
        order: 'desc',
        by_user: true,
        with_expired: false,
        search: this.filters.search,
        only_on_progress: false,
        highlight_education: 1,
      }

      self.$store.dispatch(GET_ITEMS, {
        url: self.assignedEducationsUrl,
        acceptPromise: false,
        showLoading: true,
        filters: filter
      }).then(result => {
        if (result.status) {
          result.data.data.forEach(data => {

            if (!data.is_expired)
              self.highlight_educations.push(data)
          });
        }
      })
    },
    getPopularEducations() {
      let self = this;
      let filter = {
        page: 1,
        perPage: 4,
        order_by: 'created_at',
        order: 'desc',
        by_user: true,
        with_expired: false,
        search: this.filters.search,
        only_on_progress: false,
        only_popular: true,
      }
      self.$store.dispatch(GET_ITEMS, {
        url: self.assignedEducationsUrl,
        acceptPromise: false,
        showLoading: true,
        filters: filter
      }).then(result => {
        if (result.status) {
          this.popular_educations = result.data
        }
      })
    },
    getExpiredEducations() {
      let self = this;
      let filter = {
        page: 1,
        perPage: 4,
        order_by: 'created_at',
        order: 'desc',
        by_user: true,
        with_expired: true,
        search: this.filters.search,
        only_on_progress: false,
      }
      self.$store.dispatch(GET_ITEMS, {
        url: self.assignedEducationsUrl,
        acceptPromise: false,
        showLoading: true,
        filters: filter
      }).then(result => {
        if (result.status) {
          this.expired_educations = result.data
        }
      })
    },
    getLinkedinContents() {
      let self = this;
      let filter = {
        limit: 4,
        provider: 'linkedin'
      }
      self.$store.dispatch(GET_ITEMS, {
        url: self.providersAssignmentsUrl,
        filters: filter,
        limit: 4,
      }).then(result => {
        if (result.status) {
          this.linkedin_contents = result.data
        }
      })
    },
    getUdemyContents() {
      let self = this;
      let filter = {
        limit: 4,
        provider: 'udemy'
      }
      self.$store.dispatch(GET_ITEMS, {
        url: self.providersAssignmentsUrl,
        filters: filter,
        limit: 4,
      }).then(result => {
        if (result.status) {
          this.udemy_contents = result.data
        }
      })
    },
    openProviderDetails(result) {
      axios.get(process.env.VUE_APP_BACKEND_APP_URL + "/api/provider-content-show/" + result.provider + "/" + result.id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.secureLocalStorage("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.$router.push({ name: "user.assignment-detail", params: { id: response.data.assignment_id } });
          } else {
            // TODO kontrol edilmeli 404 gelebilyior
          }
        });
    },
    getCollections() {
      if (!this.CollectionVisible) {
        return false;
      }
      let self = this;
      self.$store.dispatch(GET_ITEMS, {
        url: self.collectionUrl,
        filters: {
          search: this.filters.search
        },
        acceptPromise: false,
        showLoading: true,
      }).then(result => {
        if (result.status && result.data.my_collections) {
          this.collections = result.data.my_collections
        }
      })
    },
    getDashboardData() {
      let self = this;
      self.$store.dispatch(GET_ITEMS, {
        url: self.dashboardUrl,
        acceptPromise: false,
        showLoading: true,
      }).then(result => {
        if (result.status) {
          self.dashboard_item = result.data
          self.dashboard_item.advertisements = this.rotationFunction(self.dashboard_item.advertisements);
        }
      })
    },
    rotationFunction(arr) {
      if (arr.length <= 1) {
        return arr;
      }
      return arr.map((_, index, array) =>
        array[(index + 1) % array.length]);
    },
    getLeaderBoardData() {
      if (!this.leaderTableVisible) {
        return false;
      }
      let self = this;
      self.$store.dispatch(GET_ITEMS, {
        url: self.leaderBoardUrl,
        acceptPromise: false,
        showLoading: true,
      }).then(result => {
        if (result.status) {
          self.leader_board = result.data
        }
      })
    },
    getSelfRegistrableTrainings() {
      let self = this;
      self.$store.dispatch(GET_ITEMS, {
        url: self.selfRegistrableUrl,
        acceptPromise: false,
        showLoading: true,
      }).then(result => {
        if (result.status) {
          this.selfRegistrableTrainigs = result.data?.data?.data
          // self.leader_board = result.data
        }
      })
    },
    getLastPosts() {
      if (!this.socialPostVisible) {
        return false;
      }
      let self = this;
      self.$store.dispatch(GET_ITEMS, {
        url: self.lastPostsUrl,
        acceptPromise: false,
        showLoading: true,
      }).then(result => {
        if (result.status) {
          self.last_posts = result.data
        }
      })
    },
    getCurrentUserPosition() {
      return this.leader_board.userIndex;
    },
    fixDate(value) {
      return moment(value).locale(this.secureLocalStorage("language")).fromNow(true);
    },
    timeFromDate(value) {
      const today = new Date()
      var inputDate = new Date(value);
      var todaysDate = new Date();
      // if (inputDate < todaysDate) {
      return inputDate ? moment(inputDate).locale(this.secureLocalStorage("language")).format('DD MMMM YYYY') : ''
      // } else {
      //   return moment(value).locale(this.secureLocalStorage("language") === 'en' ?
      //     this.secureLocalStorage("language") + 'gb' : this.secureLocalStorage("language")).fromNow() + this.$t('başlıyor');
      // }
    },
    onSubmitSearch() {
      this.$router.push({
        name: "User.search-result",
        params: { search: this.search },
      });
    },
    searchResults(val) {
      let search = val.trim();
      if (this.$route.name !== 'User.search') {
        this.$router.push({ name: 'User.search', query: { search: search } })
      }
    },
    education() {
      this.$router.push({ name: "User.eduction-detail" });
    },
    handleOnClickCard(payload) {
      this.$router.push({
        name: "User.eduction-detail",
        params: { id: payload },
      });
    },
    fetchData() {
      this.getAssignedEducations()
      this.getHighlightEducations()
      this.getPopularEducations()
      this.getCollections()
      this.getLinkedinContents()
      this.getUdemyContents()
      this.getDashboardData()

      this.getExpiredEducations()
      this.getSelfRegistrableTrainings()
    },
    openInformationUser() {
      if (this.subdomain === 'next4bizacademy.com')
        UIkit.modal("#informationForNext4biz").show();
      else if ((this.subdomain !== 'skyacademy.softinya.com') && (this.subdomain !== 'next4bizacademy.com')) {
        UIkit.modal("#informationForUser").show();
      }
    },
    setModuleList() {
      if (ls.get('dashboardMenuList')) {
        this.moduleList = ls.get('dashboardMenuList');
        this.moduleList.dashboard = this.moduleList?.dashboard?.sort((a, b) => parseFloat(a.order) - parseFloat(b.order)) ?? null;
        this.leaderTableVisible = this.moduleList?.dashboard.find((element) => element.type === 'leader_table')?.status === 1 ? true : false
        this.socialWallVisible = this.moduleList?.dashboard.find((element) => element.type === 'social_wall')?.status === 1 ? true : false
        this.getLeaderBoardData()
        this.getLastPosts()
      } else {
        axios.get(process.env.VUE_APP_BACKEND_APP_URL + "/api/widgets", {
          params: {
            company_id: this.authUser.company_id
          },
        }).then((response) => {
          this.moduleList = response.data;
          this.moduleList['menu'] = this.moduleList?.menu?.sort((a, b) => parseFloat(a.order) - parseFloat(b.order)) ?? null;
          ls.set("dashboardMenuList", this.moduleList);
          this.moduleList.dashboard = this.moduleList?.dashboard?.sort((a, b) => parseFloat(a.order) - parseFloat(b.order)) ?? null;
          this.leaderTableVisible = this.moduleList?.dashboard?.find((element) => element.type === 'leader_table')?.status === 1 ? true : false
          this.socialWallVisible = this.moduleList?.dashboard?.find((element) => element.type === 'social_wall')?.status === 1 ? true : false
          this.getLeaderBoardData()
          this.getLastPosts()
        });
      }
    }
  },
  mounted() {
    this.setModuleList();
    this.subdomain = window.location.hostname;
    if (!window.localStorage.getItem("informationText")) {
      this.openInformationUser()
      window.localStorage.setItem("informationText", true);
    }

    this.current_user = this.authUser
    this.fetchData()
    this.birthDayModal()
  }
};
</script>

<style scoped>
.section-header h4 i {
  color: #666CFF;

}

.birthdaycard {
  position: relative;
  width: 250px;
  height: 350px;
  cursor: pointer;
  transform-style: preserve-3d;
  transform: perspective(2500px);
  transition: 1s;
}

.birthdaycard:hover {
  transform: perspective(2500px) rotate(5deg);
  box-shadow: inset 100px 20px 100px rgba(0, 0, 0, 0.2), 0 10px 100px rgba(0, 0, 0, 0.4);
}

.birthdaycard:hover .cardfront {
  transform: rotateY(-160deg);
}

.birthdaycard:hover .happy {
  visibility: hidden;
}

.cardfront {
  position: relative;
  background-color: #ffffff;
  width: 250px;
  height: 350px;
  overflow: hidden;
  transform-origin: left;
  box-shadow: inset 100px 20px 100px rgba(0, 0, 0, 0.2), 30px 0 50px rgba(0, 0, 0, 0.4);
  transition: 0.6s;
}

.happy {
  font-family: Tahoma, sans-serif;
  text-align: center;
  margin: 30px;
  background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
  transition: 0.1s;
}

.balloons {
  position: absolute;
}

.balloon1,
.balloon2,
.balloon3,
.balloon4 {
  position: absolute;
  width: 85px;
  height: 95px;
  border-radius: 50%;
}

.balloon1 {
  background-color: rgba(239, 71, 111, 0.7);
  left: -10px;
  top: 50px;
}

.balloon2 {
  background-color: rgba(6, 214, 160, 0.7);
  left: 50px;
  top: 20px;
}

.balloon3 {
  background-color: rgba(255, 209, 102, 0.7);
  left: 110px;
  top: 50px;
}

.balloon4 {
  background-color: rgba(17, 138, 178, 0.7);
  left: 170px;
  top: 20px;
}

.balloon1:before,
.balloon2:before,
.balloon3:before,
.balloon4:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 155px;
  background-color: #ffd166;
  top: 95px;
  left: 43px;
}

.balloon1:after,
.balloon2:after,
.balloon3:after,
.balloon4:after {
  content: "";
  position: absolute;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  top: 94px;
  left: 37px;
}

.balloon1:after {
  border-bottom: 10px solid #ef476f;
}

.balloon2:after {
  border-bottom: 10px solid #06d6a0;
}

.balloon3:after {
  border-bottom: 10px solid #ffd166;
}

.balloon4:after {
  border-bottom: 10px solid #118ab2;
}

.cardinside {
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 0;
  width: 250px;
  height: 350px;
  box-shadow: inset 100px 20px 100px rgba(0, 0, 0, 0.2);
  z-index: -1;
}

.cardinside p {
  font-family: 'Brush Script MT', cursive;
  margin: 20px;
  color: #333;
}

.name {
  position: absolute;
  left: 120px;
  top: 220px;
  color: #333;
}

.back {
  font-family: Tahoma, sans-serif;
  color: #333;
  text-align: center;
  margin: 30px;
  outline-color: #333;
  outline-style: dotted;
}

.cardinside p {
  font-size: 22px
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.modal-dialog {
  display: inline-block;
  vertical-align: middle;
}

.modal .modal-content {
  padding: 20px 20px 20px 20px;
  -webkit-animation-name: modal-animation;
  -webkit-animation-duration: 0.5s;
  animation-name: modal-animation;
  animation-duration: 0.5s;
}

@-webkit-keyframes modal-animation {
  from {
    top: -100px;
    opacity: 0;
  }

  to {
    top: 0px;
    opacity: 1;
  }
}

@keyframes modal-animation {
  from {
    top: -100px;
    opacity: 0;
  }

  to {
    top: 0px;
    opacity: 1;
  }
}

.displayBlock {
  display: block !important;
}

.uk-card-primary {
  background-color: var(--backgroundColor) !important;


}
</style>
