<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>
            <li>{{ this.$t('menu.company_dashboard.manage_posts') }}</li>
          </ul>
        </nav>
      </div>
      <template v-if="errorMessages">
        <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red">
            <i class="uil-exclamation-triangle"></i> {{ error }}
          </p>
        </div>
      </template>
      <div v-if="successMessage" class="uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: green">
          <i class="uil-exclamation-triangle"></i> {{ successMessage }}
        </p>
      </div>

      <div class="card">
        <!-- Card header -->
        <div class="card-header actions-toolbar border-0">
          <div class="d-flex justify-content-between align-items-center">
            <!-- <h4 class="d-inline-block mb-0"></h4> -->
            <div class="row" style="width:100%">
              <div class=" col-md-1  search-icon ">
                <i class="uil-search"></i>
              </div>
              <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
                <input class="uk-search-input shadow-0 uk-form-small" type="search" v-model="filter.search"
                  :placeholder="$t('general.search')" autofocus="" />
              </div>
            </div>
            <div class="d-flex">
              <!-- Shorting -->
              <a href="#" class="btn btn-icon btn-hover btn-circle" :uk-tooltip="$t('filters.order.sort')" title=""
                aria-expanded="false">
                <i class="uil-list-ui-alt"></i>
              </a>
              <div uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up" class="uk-dropdown">
                <ul class="uk-nav uk-dropdown-nav">
                  <li @click="sortOrder('text', 'asc')">
                    <a href="javascript:void(0)">{{ $t("sorting.a_z") }}</a>
                  </li>
                  <li @click="sortOrder('text', 'desc')">
                    <a href="javascript:void(0)">{{ $t("sorting.z_a") }}</a>
                  </li>
                  <li @click="sortOrder('created_at', 'asc')">
                    <a href="javascript:void(0)">
                      {{ $t("sorting.created_at_asc") }}</a>
                  </li>
                  <li @click="sortOrder('created_at', 'desc')">
                    <a href="javascript:void(0)">
                      {{ $t("sorting.created_at_desc") }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "@/assets/components/dataTable/DataTable";
import store from "@/core/services";
import moment from "moment";
import comment_module, {
  MODULE_NAME,
  ITEMS,
  GET_ITEMS,
  UPDATE_ITEM,
  DELETE_ITEM,
  ERROR,
} from "@/core/services/store/comment.module";
import Swal from "sweetalert2";
import company_module, {
  ONLINE_USERS,
  MODULE_NAME as COMPANY_MODULE,
} from "@/core/services/store/company.module";

import {
  GET_ITEM_DETAIL_BY_ID,
  UPDATE_ITEM_BY_ID,
} from "@/core/services/store/REST.module";
import Pagination from "@/assets/components/Pagination";
const _COMPANY_MODULE = COMPANY_MODULE;
import axios from "axios";
export default {
  name: "content",
  components: {
    DataTable,
    Pagination
  },
  data() {
    return {
      companyUrl: "api/company/self",
      companySharingUrl: "api/company/experience-sharing/",
      sharesUrl: "api/shares-management",
      status_id: 1,
      pagination: null,
      activeItem: null,
      successMessage: null,
      errorMessages: [],
      page: 1,
      company: null,
      experience_shareable: null,
      per_page: 3,
      filter: {
        search: null,
        order: "desc",
        sort: "created_at",
      },
    };
  },
  beforeCreate() {
  },
  computed: {
    onlineUsers() {
      return store.getters[_COMPANY_MODULE + "/" + ONLINE_USERS];
    },
    comments: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEMS];
      },
      set(value) { },
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
  },
  methods: {
    sortOrder(sort, order) {
      let self = this;
      self.page = 1;
      self.filter.order = order;
      self.filter.sort = sort;

      this.getCommentsResult();
    },
    setPage(page) {
      let self = this;
      self.page = page;
      this.getCommentsResult();
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
  },
  mounted() {
  },
  beforeDestroy() {
    const modal = UIkit.modal("#shareSettings");
    if (modal) modal.$destroy(true);
  },
  watch: {
    "filter.search": function (val) {
      this.page = 1;
      this.debouncedFilterSearch();
    },
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedFilterSearch = _.debounce(this.getCommentsResult, 500);
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },
};
</script>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
